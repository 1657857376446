<template>
  <v-app dark>
    <router-view />
  </v-app>
</template>

<script>
import VApp from "vuetify/lib/components/VApp/VApp"

export default {
  components: { VApp },
  name: `LayoutLogin`,
}
</script>
